import * as React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        height: 30,
        width: 30,
        textAlign: "center",
        borderRadius: 10,
        paddingTop: 5,
        paddingBottom: 5,
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "black",
        height: 30,
        width: 30,
        textAlign: "center",
        borderRadius: 10,
        paddingTop: 5,
        paddingBottom: 5,
      }}
      onClick={onClick}
    />
  );
}

const PortfolioPostTemplate = ({ data, location }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  //const { previous, next } = data;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="blog-single">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-lg-9 m-15px-tb mx-auto">
              <article
                style={{
                  background: "none",
                  paddingTop: 80,
                  boxShadow: "none",
                }}
                className="article"
              >
                <div className="article-title">
                  <h2>{post.frontmatter.title}</h2>
                  {post.frontmatter.isGitHubLink === "yes" ? (
                    <div className="media d-flex align-items-center">
                      <div style={{ cursor: "pointer" }}>
                        <StaticImage
                          className="img-fluid"
                          formats={["auto", "webp", "avif"]}
                          src="../images/github-logo.png"
                          quality={95}
                          width={30}
                          height={30}
                          alt="Author"
                        />
                      </div>
                      <div
                        onClick={() => {
                          window.open(post.frontmatter.gitHubLink, "_blank");
                        }}
                        style={{ cursor: "pointer" }}
                        className="media-body"
                      >
                        <span style={{ fontSize: 16, fontWeight: "bold" }}>
                          View Project on github
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
                <h4>Screenshots</h4>
                <div className="p-3">
                  <Slider {...settings}>
                    {post.frontmatter.images &&
                      post.frontmatter.images.length > 0 &&
                      post.frontmatter.images.map((data, index) => {
                        return (
                          <div key={index}>
                            <img
                              style={{ minHeight: "400px", maxHeight: "400px" }}
                              src={data}
                              alt="portfolio-img"
                              className="img-fluid w-100"
                            />
                          </div>
                        );
                      })}
                  </Slider>
                </div>

                <div
                  style={{ fontSize: "20px" }}
                  dangerouslySetInnerHTML={{ __html: post.html }}
                  className="article-content"
                ></div>
              </article>
              {/*<nav style={{ fontSize: "16px" }} className="blog-post-nav">
                <ul
                  style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                  }}
                >
                  <li>
                    {previous && (
                      <Link to={previous.fields.slug} rel="prev">
                        ←{" "}
                        {previous.frontmatter.title.length > 40
                          ? previous.frontmatter.title
                              .substring(0, 40)
                              .concat("...")
                          : previous.frontmatter.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title.length > 40
                          ? next.frontmatter.title
                              .substring(0, 40)
                              .concat("...")
                          : next.frontmatter.title}{" "}
                        →
                      </Link>
                    )}
                  </li>
                </ul>
                        </nav>*/}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PortfolioPostTemplate;

export const pageQuery = graphql`
  query PortfolioPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        isGitHubLink
        gitHubLink
        isDemoLink
        images
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
